<template>
  <div name="otp">
    <!-- Here, we define a slot to pass data to our component -->
    <slot
      :digits="digits"
      :isvalid="isValid"
      :onblur="onBlur"
      :oninput="onInput"
      :onpaste="onPaste"
    />

    <!-- Another slot for handling errors -->
    <slot name="error" :message="errorMessage" :haserror="error" />
  </div>
</template>
<script>

export default {
  name: 'otp-component', // Giving our component a name

  props: {
    value: String, // This is where we receive input from the user
    count: { // This is the place where users will specify the number of input fields.
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      error: false, // We start without errors
      errorMessage: '', // No error message initially
      digits: Array(this.count).fill(''), // Create an array to store our OTP digits
    };
  },

  computed: {
    isValid() {
      return this.digits.length === this.value.length; // We check if all OTP digits are filled
    },
  },

  mounted() {
    if (this.value) this.digits = this.value?.split('');
    // When our component is ready, we check if there's already an OTP provided and fill it in
  },

  methods: {
    onInput(index, digit) {
      console.log(index, digit);
      // This function handles user input for each OTP digit
      switch (typeof digit) {
      case 'string':
        this.digits[index] = digit;
        break;
      case 'number':
        this.digits[index] = digit?.toString();
        break;
      default:
        this.digits[index] = digit?.data ? digit?.data : '';
        break;
      }

      this.$emit('input', this.digits?.join(''));
      this.checkDigits()
      // We update the OTP digits and notify the parent component of changes
    },

    onPaste(index, event) {
      console.log('!!!!!');
      event.preventDefault();
      const clipboardData = event?.clipboardData || window?.clipboardData;
      console.log(clipboardData);
      if (clipboardData) {
        const pastedText = clipboardData.getData('text').trim();

        // Remove non-digit characters from the pasted text
        const parsedPastedText = pastedText.replace(/\D/g, '');

        // Initialize an array for the new digits
        const newDigits = Array(this.count).fill('');

        // Check if the pasted text length exceeds the available input fields
        console.log(parsedPastedText, this.digits.length <= parsedPastedText?.length, this.digits.length, parsedPastedText?.length);
        if (this.digits.length <= parsedPastedText?.length) {
          this.error = true;
          this.errorMessage = 'Invalid characters in the pasted OTP.';
          console.log(this.error, this.errorMessage);
        }

        // Copy parsed digits into the newDigits array, starting from the current index
        for (let i = 0; i < parsedPastedText.length; i++) {
          if (index + i < newDigits.length) {
            newDigits[index + i] = parsedPastedText[i];
          }
        }

        // Update the digits with the newly pasted values
        this.digits = newDigits;
        this.$emit('input', this.digits.join(''));
      } else {
        // Handle cases where clipboard data is unavailable
        this.error = true;
        this.errorMessage = 'Invalid characters in the pasted OTP.';
      }
    },

    onBlur() {
      // This function is called when the input field loses focus
      this.checkDigits();
    },

    clearDigits() {
      // This function clears all OTP digits and errors
      this.error = false;
      this.digits.fill('');
      this.errorMessage = '';
      this.$nextTick(() => {
        const [first] = [...document.getElementsByClassName('otp-input')]
        if (first) first.focus()
      })
    },

    checkDigits() {
      // You can add custom validation logic here
      if (!this.isValid) {
        this.errorMessage = 'Please enter a valid OTP';
        this.error = true;
      } else {
        this.errorMessage = '';
        this.error = false;
      }
    },
  },
};
</script>
