<template lang="pug">
otp-component(v-model='pin' :value='pin' :count='length' ref='otpComponent')
  template(#default='{ digits, oninput, onpaste, onblur }')
    .otp-wrap
      otp-group.flex.space-x-2.p-2
        template(#default='{ focusNext, focusPrev }')
          otp-group-input(
            v-for='(digit, index) in digits'
            :key='index' :value='digit'
            autofocus='' placeholder=''
            @blur='onblur' @next='focusNext(index)'
            @prev='focusPrev(index)'
            @paste='onpaste(index, $event); $emit("input", pin)'
            @input='oninput(index, $event); $emit("input", pin)'
          )
      button.otp-btn(@click.prevent='clearPin')
        svg(xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor')
          path(stroke-linecap='round' stroke-linejoin='round' d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z')
  //- template(#error='{ message, haserror }')
  //-   otp-error-message(:message='message' :has-error='haserror')
</template>

<script>
export default {
  name: 'OtpInput',
  props: {
    value: {},
    length: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      pin: '',
    };
  },
  methods: {
    clearPin() {
      this.pin = '';
      this.$refs.otpComponent.clearDigits();
    },
  },
}
</script>
