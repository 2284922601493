<template lang="pug">
  include /mixins
  +b.review-view()
    ui-accordion(
    :init='false'
    :id='review.id'
    )
      template(#default='{ activeState, toggleActive, collapse,  style }')
        +b.tt-accordion--review(
          :class='{"ns-accordion--is-open": activeState}',
          :id='review.id'
        )
          div
            +e.question--clear()
              +b.review
                +e.top
                  +b.review-author
                    +e.img-wrap
                      +e.img.IMG(
                        :src='review.props.user && review.props.user.props.img && review.props.user.props.img.catalog_preview ? review.props.user.props.img.catalog_preview : "/static/img/rebranding/non.png"'
                      )
                    +e.name.P {{ review.props.name }}
                  +e.rate
                    pretty-stars(
                      :rating='review.props.rate'
                      :read-only='true'
                    )
                    +e.text.P  {{ review.props.created_at }}
                +e.content
                  +e.body.P {{ review.props.comment }}
                  +b.g-row--appearance_spaced.--space_lg.media(
                    v-if='showMedia'
                  )
                    +b.g-cols--6.--4-md.--6-xl.--3-mac.g-cell(
                      v-for='(photo, i) in review.props.photos',

                    )
                      lightbox.pointer(
                        classname='ds-aspect-ratio--ratio_1x1'
                        :key="`photo${photo.id}`"
                        :source='photoMap(review.props.photos)'
                      )
                        template(
                          #default='{ show }'
                        )
                          +b.media-preview(
                            @click='show(i)'
                          )
                            +e.img.IMG(
                              :src='photo.props.img'
                            )
                    +b.g-cols--6.--4-md.--6-xl.--3-mac.g-cell(
                      v-for='(video, i) in review.props.videos',
                      :key="`video${video.id}`"
                    )
                      +b.ds-aspect-ratio--appearance_filled.--ratio_16x9.FIGURE
                        +e.body
                          +b.media-preview.pointer
                            ui-youtube-view(
                              :url='video.props.url'
                            )
                              template(#default='{ play, preview }')
                                +e.mask(
                                  @click='play'
                                )
                                  +e.img.IMG(
                                    :src='preview'
                                  )
                                  +e.play.icon-play
                +e.footer
                  +e.bottom
                    +b.tt-link--variant_end.A(
                      href=''
                      @click.prevent='toggleActive'
                      v-if='review.props.children.length'
                    )
                      +e.text.P.ds-caption--color_accent.--size_xs.--light {{ activeState ? _('Скрыть ответы') : _('Читать ответы') }} ({{ review.props.children.length }})
                        +e.icon.mdi(
                          :class='style.icon'
                        )
                    +b.tt-link--variant_start.BUTTON(
                      @click.prevent='replier ? replier(review) : $emit("reply", review.id, review.id, review.props.name)'
                    )
                      +e.icon.icon-right
                      +e.text.P.ds-caption--color_accent.--size_xs.--light {{ _('ответить') }}
                  .tt-accordion__description.tt-accordion__description--spaced(:style='{maxHeight: "initial"}', v-if='activeState')
                    +e.question--clear(
                      v-for='child in review.props.children'
                    )
                      +b.review--answer
                        +e.top
                          +b.review-author
                            +e.img-wrap
                              +e.img.IMG(
                                :src='child.props.avatar || "/static/img/rebranding/non.png"'
                              )
                            +e.name.P {{ child.props.name }}
                          +e.rate
                            +e.text.P {{ child.props.created_at }}
                        +e.content
                          +e.body.P {{ child.props.comment }}
</template>

<script>
export default {
  name: 'ReviewDetail',
  props: {
    review: {
      type: Object,
      default: () => {},
    },
    replier: {
      type: Function,
    },
    showMedia: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    photoMap(arr) {
      const [first] = arr.map(el => el.props.img)
      return first
    },
  },
}
</script>
