import {
  compose,
  defaultProps,
  setName,
} from 'vue-compose'
import { withValidation } from 'vee-validate'

import UnifiedControlDescriptor from './UnifiedControlDescriptor'
import BoundLabel from './BoundLabel'
import StaticLabel from './StaticLabel'
import ErrorComponent from './ErrorComponent'
import AppendComponent from './AppendComponent'
import PhoneInput from './PhoneInput'
import CountryInput from './CountryInput'

import MultiSelect from './Multiselect'
import Calendar from './DatePicker'
import Captcha from '@app/UiElements/Controls/Captcha'

const SimpleInput = compose(
  setName('SimpleInput'),
  defaultProps({
    labelComponent: BoundLabel,
    errorComponent: ErrorComponent,
    component: 'Input',
  })
)(UnifiedControlDescriptor)

const SimpleInputV = withValidation(SimpleInput, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0],
}))
const SimpleTextareaV = withValidation(
  SimpleInput, ({ required, errors }) => ({
    isRequired: required,
    error: errors[0],
    component: 'textarea',
  })
)

const StaticInput = compose(
  setName('StaticInput'),
  defaultProps({
    labelComponent: StaticLabel,
    errorComponent: ErrorComponent,
    component: 'Input',
  })
)(UnifiedControlDescriptor)

const Multiselect = compose(
  setName('Multiselect'),
  defaultProps({
    labelComponent: BoundLabel,
    errorComponent: ErrorComponent,
    component: 'MultiSelect',
  })
)(UnifiedControlDescriptor)

const MultiselectV = withValidation(Multiselect, ({ required, errors }) => ({
  isRequired: required,
  error: errors[0],
}))

const DatePicker = compose(
  setName('DatePicker'),
  defaultProps({
    labelComponent: BoundLabel,
    innerClearBtn: true,
    appendComponent: AppendComponent,
    // errorComponent: ErrorComponent,
    component: 'Calendar',
  })
)(UnifiedControlDescriptor)

export default function plugins(Vue, { prefix = 'Control' } = {}) {
  Vue.component(`${prefix}SimpleInput`, SimpleInput)
  Vue.component(`${prefix}SimpleInputV`, SimpleInputV)
  Vue.component(`${prefix}SimpleTextareaV`, SimpleTextareaV)
  Vue.component(`${prefix}StaticInput`, StaticInput)
  Vue.component(`${prefix}Multiselect`, Multiselect)
  Vue.component(`${prefix}MultiselectV`, MultiselectV)
  Vue.component(`${prefix}DatePicker`, DatePicker)
  Vue.component('MultiSelect', MultiSelect)
  Vue.component('Captcha', Captcha)
  Vue.component('Calendar', Calendar)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
  Vue.component('AppendComponent', AppendComponent)
  Vue.component('PhoneInput', PhoneInput)
  Vue.component('CountryInput', CountryInput)
}
