import {
  parallaxView,
} from '@utils/parallax'

import {
  fortuneWheel,
} from '@utils/fortune-wheel'

import seoTextTrigger from '@utils/seo-text'
import accordion from '@utils/accordion'
import roundPrices from '@utils/intl'
import addHorizontalScrolling from '@utils/horizontal-scrolling'

import sliderControl from '@utils/slider-controls'

import '@utils/filters'

import SnapSlider from '@tannerhodges/snap-slider';

import {
  checkLogo,
  scrolly,
  fakeModalCloser,
  zIndexFixer,
} from './ui-scripts'

import {
  typoHandler,
} from './typo'

// const slider = new SnapSlider('')
document.addEventListener('DOMContentLoaded', () => {
  roundPrices()
  checkLogo()
  scrolly()
  fortuneWheel()
  parallaxView()
  sliderControl()
  seoTextTrigger()
  accordion()
  fakeModalCloser()
  zIndexFixer()
  addHorizontalScrolling()
})

typoHandler()
console.log("🚀 ~ typoHandler:", typoHandler)
