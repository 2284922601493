<template>
  <input class="otp-input"
    v-model="digit"
    type="text"
    :maxlength="1"
    @input="$event => $emit('input', $event)"
    @paste="$event => $emit('paste', $event)"
    @blur="$event => $emit('blur', $event)"
  />
</template>

<script>
export default {
  name: 'otp-group-input',

  props: {
    value: String,
  },

  data() {
    return {
      digit: '',
    };
  },

  watch: {
    value() {
      this.setInitialValue();
    },

    digit(newValue, oldValue) {
      if (newValue !== oldValue) {
        if ('' === newValue) {
          this.emitPrev();
        } else {
          this.emitNext();
        }
      }
    },
  },

  methods: {
    emitPrev() {
      this.$emit('prev');
    },
    emitNext() {
      this.$emit('next');
    },

    setInitialValue() {
      switch (typeof this.value) {
      case 'string':
        this.digit = this.value;
        break;
      case 'number':
        this.digit = this.value?.toString();
        break;

      default:
        this.digit = this.value?.data ? this.value?.data : '';
        break;
      }
    },
  },
}
</script>
