import { withDefaultProps } from '@aspectus/vue-utils'

import BaseView from '@app/ListSimple/View'

import ColumnController from '@app/ListSimple/ColumnController'
import Controller from '@app/ListSimple/Controller'

import {
  attributesResource,
  salesListUrl,
  salesAttributesResource,
  partnersListUrl,
  partnersAttributesResource,
  salesDetailUrl,
  videoUrl,
  videoAttributesResource,
  brandsAttributesResource,
  brandDetailUrl,
  attachmentsDetailUrl,
  attachmentAttributesResource,
  availableAttributesResource,
  knowledgeBaseCardsResource,
  knowledgeBaseFiltersResource,
  blogAttributesResource,
  blogUrl,
  availableAttributesForBrandResource,
  salesDetailAttributesResource,
  offersListCardsResource,
  offersAttributesResource,
  BlogAvailableFiltersResource,
  VideoAvailableFiltersResource,
  SpecialOfferAvailableFiltersResource,
  AttachmentAvailableFiltersResource,
  ArticleAvailableFiltersResource,
  PartnersAvailableFiltersResource,
  SalesAvailableFiltersResource,
  vacanciesUrl,
  jobVacancyFiltersResource,
  VacancyAvailableFiltersResource,
  DiscountAvailableFiltersResource,
  discountFiltersResource,
} from '@api/catalog.service'

const composer = (
  listUrl,
  filtersUrl,
  availableFiltersUrl,
  needPrices = false,
  needSorting = false,
  needTitle = false,
  needSearch = false,
  needBlocking = false,
  controller= () => Controller
) => withDefaultProps({
  productsResource: () => listUrl,
  attributesResource: () => filtersUrl,
  availableAttributesResource: () => availableFiltersUrl,
  needPrices,
  needSorting,
  needTitle,
  needSearch,
  needBlocking,
  controller,
})(BaseView)

// const BrandCatalogView = composer(
//   brandDetailUrl,
//   brandsAttributesResource,
//   availableAttributesForBrandResource,
//   true,
//   true,
//   true,
//   false,
//   true
// )

const SalesView = composer(
  salesListUrl,
  salesAttributesResource,
  SalesAvailableFiltersResource,
  false,
  false,
  false,
  false,
  true
)
const PartnersView = composer(
  partnersListUrl,
  partnersAttributesResource,
  PartnersAvailableFiltersResource,
  false,
  false,
  false,
  false,
  true
)
// const SalesDetailView = composer(
//   salesDetailUrl,
//   salesDetailAttributesResource,
//   availableAttributesForBrandResource,
//   true,
//   false,
//   false,
//   false,
//   true
// )
const VideoView = composer(
  videoUrl,
  videoAttributesResource,
  VideoAvailableFiltersResource,
  false,
  true,
  false,
  true,
  true
)
const BlogView = composer(
  blogUrl,
  blogAttributesResource,
  BlogAvailableFiltersResource,
  false,
  true,
  false,
  true,
  true
)
const OffersView = composer(
  offersListCardsResource,
  offersAttributesResource,
  SpecialOfferAvailableFiltersResource,
  false,
  false,
  false,
  false,
  true
)
const AttachmentsView = composer(
  attachmentsDetailUrl,
  attachmentAttributesResource,
  AttachmentAvailableFiltersResource,
  false,
  true,
  false,
  true,
  true
)

const KnowledgeBaseView = composer(
  knowledgeBaseCardsResource,
  knowledgeBaseFiltersResource,
  ArticleAvailableFiltersResource,
  false,
  false,
  false,
  true,
  true
)
const VacanciesBaseView = composer(
  vacanciesUrl,
  jobVacancyFiltersResource,
  VacancyAvailableFiltersResource,
  false,
  false,
  false,
  true,
  true,
  ColumnController
)

export default function plugin(Vue, { postfix = '-simple-filters' } = { }) {
  // Vue.component(CatalogHOC.name, CatalogHOC)
  // Vue.component(`brand-catalog${postfix}`, BrandCatalogView)
  Vue.component(`blog${postfix}`, BlogView)
  Vue.component(`sales${postfix}`, SalesView)
  // Vue.component(`sales-detail${postfix}`, SalesDetailView)
  Vue.component(`partners${postfix}`, PartnersView)
  Vue.component(`video${postfix}`, VideoView)
  Vue.component(`attachments${postfix}`, AttachmentsView)
  Vue.component(`knowledge-base${postfix}`, KnowledgeBaseView)
  Vue.component(`offers${postfix}`, OffersView)
  Vue.component(`vacancies${postfix}`, VacanciesBaseView)
}
