<template lang="pug">
  include /mixins
  +b.MODAL-TRIGGER(
    url='Cart/View',
    :classes='["cart-modal"]'
    name='CartView'
  )
    template(#default='{ open }')
      +b.circle-btn(@click='open()')
        +e.icon.icon-cart.SPAN
        +e.counter.SPAN {{ cart_info.quantity || 0 }}

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CartPreview',
  props: ['withGetter'],
  data() {
    return {

    }
  },
  computed: {
    ...mapState('cart', [
      'cart_info',
    ]),
  },
  created() {
    if (this.withGetter) this.getCartInfo()
  },
  methods: {
    ...mapActions('cart', [
      'getCartInfo',
    ]),
  },
}
</script>
