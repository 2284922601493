import {
  getAuth,
} from 'firebase/auth';

import {
  initializeApp,
} from 'firebase/app';

import {
  getConfig,
} from '@/js/firebase/config';

import { toggleGoogleSignIn } from './google.js'
import { toggleAppleSignIn } from './apple.js'
import { handleAppleRedirectResult } from './handler.js'
import { getOs, APPLE_AVAILABLE_OS_LIST, isIosSafari } from './helpers.js'

initializeApp(getConfig())

export const auth = getAuth()

export function initFirebase() {
  const googleBtn = document.getElementById('js-google-sign-in')
  const appleBtn = document.getElementById('js-apple-sign-in')
  if (googleBtn) toggleGoogleSignIn(googleBtn, auth)
  if (appleBtn) {
    toggleAppleSignIn(appleBtn, auth)
    if (isIosSafari()) {
      appleBtn.parentElement.style.display = 'block'
      return
    }
    googleBtn.parentElement.classList.remove('g-cols--6')
    googleBtn.parentElement.classList.add('g-cols--12')
  }
}

document.addEventListener('DOMContentLoaded', initFirebase)
handleAppleRedirectResult(auth)
