<template lang="pug">
  include /mixins
  +b.ds-panel--space_sm
    +e.element--offset_vertical
      +b.g-row--appearance_column
        +b.g-cell.g-cols--12.tt-space_pt--2-xl
          ui-accordion(
            :init='isDesktop'
            :is-calculate-height='false'
          )
            template(#default='{ activeState, toggleActive, collapse }')
              div
                +b.g-row--align_center.--justify_between.tt-space_mb--4-xl
                  +b.g-cell.g-cols(@click='isDesktop ? () => {} : toggleActive()',)
                    +b.ds-caption--size_md.P {{ _('Ваш заказ') }}
                      +b.ds-caption--size_md(v-if='!isDesktop')
                        +e.icon.mdi.I(
                          :class='[ activeState ? "mdi-chevron-up" : "mdi-chevron-down" ]'
                        )
                  +b.g-cell.g-cols
                    +b.tt-link--light.--variant_start.--appearance_underline.--styling_default.A(
                      :href='catalogUrl',
                    )
                      +e.title.P {{ _('Продолжить покупки') }}
                template(v-if='cart.quantity')
                  +b.ds-caption--size_2xs.--regular.--color_red(
                    v-if='!cartIsValid'
                  ) {{ _('В корзине есть товары, которых нет в необходимом Вам количестве') }}
                div(v-show='activeState')

                  cart-product(
                    v-for='(product, idx) in cart.groups'
                    :key='idx',
                    :product='product',
                    @cart:update='$emit("cart:update")'
                  )
                    template(
                      #default='{ removeFromCart, updateCart, isProductload }'
                    )
                      cart-product-ui(
                        :product='product',
                        :load='isProductload'
                        @removeFromCart='removeFromCart',
                        @updateCart='updateCart',
                      )
                  cart-set-ui(
                    v-for='set, idx in cart.sets'
                    :key='`et_${idx}`',
                    :set='set',
                    :id='idx'
                    @update:cart='updateEntities($event)'
                  )
          +b.line-h--light
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(getTotalPrice)')
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            appearance='nowrap'
            v-if='shippingPrice'
          )
            cell(
              cols='8'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Стоимость доставки:') }} #[span.ds-caption--light {{ deliveryDescription }}]
            cell(
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(shippingPrice)')
          +b.line-h--light(
            v-if='cart.parameters && cart.parameters.promocode_title'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.promocode_title'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по промокоду') }} {{ cart.parameters.promocode_title }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P(v-html='getSplittedHtml(cart.promocode_discount_summ)')
          +b.line-h--light(
            v-if='bonus'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='bonus'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Буде списано бонусів') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P -#[span(v-html='getSplittedHtml(bonus)') ]
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по акции:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P  {{ hasGlobalDiscountFromSum && hasGlobalDiscountFromSum.base.parameters.discount_amount }} %
          +b.line-h--light(
            v-if='cart.parameters && cart.parameters.site_discount'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.site_discount'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка при покупке online:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P  {{ cart.parameters.site_discount }} %
          +b.line-h--light(v-if='hasDiscount')
          //- pre {{ cart.parameters && cart.parameters.promocode_title }}
          //- pre  {{ cart.parameters && cart.parameters.site_discount }}
          //- pre {{ cart.parameters && cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum }}
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            v-if='hasDiscount && !hasBonus || !hasDiscount && shippingPrice'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого к оплате:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(calcTotal(cart.total_price))')
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            v-if='hasBonus'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого к оплате:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(calcTotal(totalSumWithBonus))')
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CartProduct from '@app/Cart/Product'
import CartProductUi from '@app/Cart/ProductUi'
import CartSetUi from '@app/Cart/SetUi'
import { analyticInitCheckout } from '@analytic/checkout'

export default {
  name: 'OrderCart',
  props: {
    bonus: {},
    bonusInfo: {},
    isFreeDelivery: {},
    shippingPrice: {},
    deliveryDescription: {},
  },
  components: {
    CartProduct,
    CartProductUi,
    CartSetUi,
  },
  data() {
    return {
      currency: window.currency,
      catalogUrl: window.baseUrl,
      isDesktop: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'coupon',
    ]),
    ...mapGetters('cart', [
      'cartIsValid',
    ]),
    hasGlobalDiscountFromSum() {
      return this.cart.groups.find(el => el.base.parameters.is_global_condition)
    },
    getTotalPrice() {
      return this.hasGlobalDiscountFromSum ||
        this.cart.parameters && this.cart.parameters.promocode_title ? this.cart.total_price_without_discounts : this.cart.total_price_without_site_discount
    },
    hasDiscount() {
      return this.cart.parameters && this.cart.parameters.promocode_title ||
        this.cart.parameters && this.cart.parameters.site_discount ||
        this.cart.parameters && this.cart.parameters.discount_from_sum_limit_action_by_items && this.hasGlobalDiscountFromSum
    },
    hasBonus() {
      return this.bonus
    },
    totalSumWithBonus() {
      return this.bonusInfo.payment_sum
    },
  },
  watch: {
    'cart.quantity': {
      handler(nval) {
        if (!nval) window.location.href = window.indexPage
      },
    },
  },
  created() {
    this.calcDesktop()
  },
  async mounted() {
    await this.getCart()
    if (this.cart && this.cart.groups) {
      analyticInitCheckout(this.cart.groups)
    }
    window.addEventListener('resize', this.calcDesktop)
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
    calcTotal(price) {
      return price + this.shippingPrice
    },
    calcDesktop() {
      this.isDesktop = 1024 < window.innerWidth
    },
  },
}
</script>
