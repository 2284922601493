<template lang="pug">
  include /mixins
  +b.ds-panel--space_none(v-if='product.element && product.element.props.product')
    ui-loader(v-if='load || localLoad')
    +e.element--indent_inside.--offset_top.product_in_cart.relative
      slot(name='gift')
        +b.product-gift-info(
          v-if='isSale && product.parameters && product.parameters.select_gift'
          @click.prevent='openGiftVariants'
        )
          +b.product-gift
            +e.icon.icon-gift.SPAN
          +e.title.tt-link--styling_default.P {{ _('Выбрать другой подарок') }}
        +b.product-gift-info(
          v-if='isSale && product.parameters && !product.parameters.select_gift'
        )
          +b.product-gift
            +e.icon.icon-gift.SPAN
      +b.card-product-inner
        +b.g-row--align_center
          +b.g-cell.g-cols--3.--2-xl.relative
            +b.tt-picture--size_auto.--size_auto-h.product__img--mini.PICTURE.relative
              source(
                type="image/webp"
                :srcset="product.element.props.img && product.element.props.img.catalog_preview_webp"
              )
              +e.body.IMG(
                v-if='product.element.props.img && product.element.props.img.catalog_preview'
                :src='product.element.props.img && product.element.props.img.catalog_preview',
                :alt='product.element.props.title',
              )
              +e.body.IMG(
                v-else
                src='https://via.placeholder.com/60x60',
              )
            +b.cart-label-wrap
              template(
                v-for='rel in relations'
              )
                +b.cart-label--static.is_sale(
                  v-if='(rel && rel.parameters && rel.element && rel.element.type !== "sales.promocode") || rel && rel.element && rel.element.props && rel.element.props.campaign && rel.element.props.campaign.id'
                )
                  +e.title.P {{ _('акция') }}
                +b.cart-label--static.is_sale(
                  v-if='(rel && rel.parameters && rel.element && rel.element.type == "sales.promocode")'
                )
                  +e.title.P {{ _('промокод') }}
              +b.cart-label--static.is-discount(
                v-if='product.parameters && product.parameters.quality_type_id'
              )
                +e.title.P {{ _('Уценка') }}
          +b.g-cell.g-cols--9.--10-xl
            +b.g-row--appearance_column.--justify_between.full-h
              +b.g-cell.g-cols--12
                +b.g-row.--justify_between.full-h
                  +b.g-cell.g-cols--12.--6-xl
                    +b.ds-caption--size_sm.--size_sm-xl.--bold.A.tt-link--variant_start(
                      :class='{ "is-disabled": !product.element.props.show_in_catalog }'
                      :href='product.element.props.url'
                    ) {{ product.element.caption }}
                  +b.g-cell.g-cols--6.--3-xl.is-visible-xl
                    slot(
                      name='desktop-price',
                      :price='product.price'
                    )
                      template(
                        v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price && $options.RED_PRICE_DESCRIPTORS.includes(saleInfo.element.type)'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(v-html='getSplittedHtml(product.price)')
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(saleInfo.parameters.calculated_price)')
                      template(
                        v-else-if='product.parameters && product.parameters.quality_type_id'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
                          v-html='getSplittedHtml(product.parameters.quality_type_default_price)'
                        )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(product.price)')
                      template(
                        v-else-if='promo && promo.parameters && promo.parameters.calculated_price'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(v-html='getSplittedHtml(product.price)')
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(promo.parameters.calculated_price)')
                      +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                        v-else
                        v-html='getSplittedHtml(product.price)'
                      )
              +b.g-cell.g-cols--12.--6-xl
                +b.ds-caption--size_2xs.--light.P.cart-product-description(v-html='product.element.props.product.props.short_description')
                +b.ds-caption--size_2xs.--medium.seller-product-name.P(
                  v-if='product.element.props.seller'
                ) {{ product.element.props.seller.props.title }}
                
              +b.g-cell.g-cols--12.--6-xl
              +b.g-cell.g-cols--12.is-visible-xl
                +b.g-row--justify_between.--align_center
                  +b.g-cell.g-cols--6
                    +b.ds-caption--size_xs.--medium.P(
                      v-if='product.element.props.weight'
                    ) {{ product.element.props.weight.props.name }}
                    +b.ds-caption--size_2xs.--medium.--color_semigrey.P(v-if='product.parameters && product.parameters.quality_type_id') {{ product.parameters.quality_type_title }}
                  slot(name='sale')
                    +b.g-cell.g-cols--6(v-if='product.parameters && product.parameters.quality_type_id')
                      +b.ds-caption--size_sm.--medium.--color_red.--appearance_right.P {{ _('Скидка') }} #[+b.ds-caption--size_3md-xl.--color_red.SPAN -{{ product.parameters.quality_type_discount }}% ]
                    +b.g-cell.g-cols--6(v-if='saleInfo && saleInfo.element && $options.DISCOUNT_CONDITION_DESCRIPTORS.includes(saleInfo.element.type)')
                      +b.ds-caption--size_sm.--medium.--color_red.--appearance_right.P {{ _('Скидка') }} #[+b.ds-caption--size_3md-xl.--color_red.SPAN {{ saleInfo.parameters.title }} ]
                    template(
                      v-else-if='!isGift && (product.parameters && !product.parameters.is_sale_applied)',
                      v-for='val in product.parameters && product.parameters.campaigns'
                    )
                      +b.g-cell.g-cols--5(
                        v-if='$options.DESCRIPTORS.includes(val.descriptor_type.value)'
                      )
                        +b.ds-caption--size_2xs.--regular.--color_red(
                          v-html='val.message[lang]'
                        )
              +b.g-cell.g-cols--12.tt-space_mt--3.is-disable-xl
                +b.g-row
                  +b.g-cell.g-cols--6
                    +b.ds-caption--size_xs.--medium.P(
                      v-if='product.element.props.weight'
                    ) {{ product.element.props.weight.props.name }}
                  +b.g-cell.g-cols--6
                    slot(
                      name='price',
                      :price='product.price'
                    )
                      template(
                        v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price && $options.RED_PRICE_DESCRIPTORS.includes(saleInfo.element.type)'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
                          v-html='getSplittedHtml(product.price)'
                        )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(
                          v-html='getSplittedHtml(saleInfo.parameters.calculated_price)'
                        )
                      template(
                        v-else-if='promo && promo.parameters && promo.parameters.calculated_price'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
                          v-html='getSplittedHtml(product.price)'
                        )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(
                          v-html='getSplittedHtml(promo.parameters.calculated_price)'
                        )
                      +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                        v-else
                        v-html='getSplittedHtml(product.price)'
                      )
                  slot(
                    name='mob-sale',
                  )
              +b.g-cell.g-cols--12(
                v-if='saleInfo && saleInfo.element && $options.DISCOUNT_CONDITION_DESCRIPTORS.includes(saleInfo.element.type)'
              )
                +b.ds-caption--size_xsm.--medium.--color_red.--appearance_right.P.is-disable-xl {{ _('Скидка') }} #[+b.ds-caption--size_sm.--color_red.SPAN {{ saleInfo.parameters.title }} ]

        row(
          justify='between'
          align='center'
          appearance='nowrap'
        )
          +b.g-cell.g-cols.--2-xl.is-visible-xl
          +b.g-cell.g-cols.--6-xl
            slot(name='counter', :count='product.quantity')
              row(align='center' :appearance='["column-reverse-till-m2d", "nowrap", "spaced"]')
                cell
                  counter(
                    :amount='product.quantity',
                    :disable='isSale || disabled'
                    :max-count='product.element.props.stock'
                    @change='$emit("updateCart", $event)'
                    :variant='product.element.id'
                    :show-error='stockInfo && !stockInfo.is_valid && stockInfo.quantity > stockInfo.stock_quantity_1c'
                  )
                cell(v-if='bonus')
                  +b.cashback-percent
                    +e.value(v-html='getSplittedHtml(bonus)')
                    +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='24', height='20', viewBox!='0 0 24 20', fill='none')
                      circle(cx='14', cy='10', r='9.5', fill='#FF7A00', stroke='white')
                      path(
                        d='M11.612 9.68L14.096 14H10.904L9.296 10.964H8.504V14H5.672V5.6H8.504V8.732H9.368L11.156 5.6H14.108L11.612 9.68Z', fill='white'
                        style='transform: translateX(4px)'
                      )

            slot(name='stock')
              template(
                v-if='isLimited && (stockInfo && stockInfo.is_valid || !stockInfo)'
              )
                +b.ds-caption--size_2xs.--regular.--color_accent(
                  v-if='!product.element.props.stock'
                ) {{ _('Нет в наличии') }}
                +b.ds-caption--size_2xs.--regular.--color_accent(
                  v-else
                ) {{ _('Макс. кол-во товаров доступное к заказу -') }} &nbsp; {{  product.element.props.stock }} {{ _('шт.') }}
              template(
                v-if='stockInfo && !stockInfo.is_valid'
              )
                +b.ds-caption--size_2xs.--regular.--color_red(
                  v-if='!stockInfo.stock_quantity_1c'
                )
                  slot(name='notvalid:delete')
                    span {{ _('Товара нет в наличии, пожалуйста удалите его из корзины') }}
                +b.ds-caption--size_2xs.--regular.--color_red(
                  v-else-if='stockInfo.quantity > stockInfo.stock_quantity_1c'
                ) {{ _('Товара нет в необходимом Вам количестве, доступно ') }} {{ stockInfo.stock_quantity_1c }}
          +b.g-cell.g-cols.--1-xl.is-visible-xl
          +b.g-cell.g-cols.--3-xl
            slot(
              name='total',
            )
              row(
                justify='end'
                align='center'
              )
                cell(
                  cols='12-xl'
                )
                  slot(
                    name='pricetips'
                  )
                    +b.ds-caption--size_sm.--light.--appearance_right.P  {{ _('Сумма:') }}
                cell(
                  cols='12-xl'
                )
                  slot(
                    name='sum',
                    :price='price'
                  )
                    template(
                      v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price'
                    )
                      +b.ds-caption--size_sm.--semibold.--color_red.--appearance_right.P(v-html='getSplittedHtml(price)')
                    
                    +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                      v-html='getSplittedHtml(price)'
                      v-else
                    )
        template(v-if='!disabled')
          +b.remove-from-cart.SPAN.mdi.mdi-close(
            @click='$emit("removeFromCart")'
            :class='{ "is-active": stockInfo && !stockInfo.stock_quantity_1c }'
            v-if='!isSale'
          )

</template>
<script>

import {
  mapActions,
} from 'vuex'

import {
  changeGifts,
  cleanGifts,
  createGifts,
} from '@api/cart.service.js'

import GiftVariants from '@app/Cart/GiftVariants'

const RED_PRICE_DESCRIPTORS = [
  'sales.pricefromquantitydescriptor',
  'sales.pricefromtotalquantitydescriptor',

]
const DISCOUNT_CONDITION_DESCRIPTORS = [
  'sales.discountfromquantitydescriptor',
  'sales.discountfromsumdescriptor',
]
const DESCRIPTORS = [
  'discount_from_quantity',
  'discount_from_summ',
  'price_from_quantity',
]

const PROMO_TYPE = 'sales.promocode'

export default {
  name: 'ProductBaseView',
  DESCRIPTORS,
  RED_PRICE_DESCRIPTORS,
  DISCOUNT_CONDITION_DESCRIPTORS,
  props: {
    isGift: {},
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    parent: {},
    relations: {
      default: () => [],
    },
    group_id: {},
  },
  data() {
    return {
      currency: window.currency,
      lang: window.language,
      localLoad: false,
    }
  },
  computed: {
    promo() {
      return this.relations.find(e => e.element.type === PROMO_TYPE)
    },
    saleInfo() {
      if (this.relations.length) {
        const [rel] = this.relations
        return rel
      }
      return {}
    },
    isLimited() {
      return this.product.quantity >= this.product.element.props.stock
    },
    stockInfo() {
      return this.product.element.stock_info
    },
    bonus() {
      return this.product?.parameters?.bonus_amount
    },
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
    openGiftVariants() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
          return
        }
        reject(new Error('Promise is rejected'))
      }).then(res => {
        this.changeGift(res)
      })
      this.$modal.show(
        GiftVariants,
        {
          id: this.parent,
          promise: pr,
          count: this.product.quantity,
          group_id: this.group_id,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
    async changeGift(data) {
      await cleanGifts.execute({}, { group_id: this.group_id })
      try {
        this.localLoad = true
        await createGifts.execute({}, data)
        await this.getCart()
        this.localLoad = false
      } catch (e) {
        this.localLoad = false
      }
    },
  },
}
</script>
